
#navigation{
    width: 100vw;
    margin: 0 auto;
    background-color: rgb(101, 101, 101);
  }
  
  nav ul{
    margin: 0 auto;
    text-align: center;
  }
  
  ul{
    list-style: none;
  }
  
  ul li{
    display: inline-block;
    margin: 20px 10px;
    text-align: center;
  }




.RoverImages {
  width: 600px;
  height: 600px;
}

.Adminphoto {
  width: 100%;
  height: 80%;
}


// Haveservice Page

.Haveservice {
  background-color: #6bb008;
  color: white;
  min-height: 100vh;
}

.hr1{
  width: 100px;
  border: 2px solid white;
  border-radius: 1px;
  opacity: 100%;
  size: 10;
}

.CurrentPic{
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.CurrentPicEdit {
  border-radius: 50%;
  display: block;
}


//FORSIDE
// .section{
//   background: rgb(200, 220, 231);
//     color: black;
//     font-family: Tahoma, Geneva, Verdana, sans-serif;
//     margin: 0;
//     padding: 14em 14em 14em 20em;
// }

// .glassImg {
//   position: absolute;
//   z-index: -2;
//   height: 100vh;
//   top: 0;
//   left: 0; 
// }

// .Glasscontainer {
//   display: inline-block;
//   background: rgb(255,255,255,0.2);
//   padding: 3em;
//   border-radius: 3em;
//   position: relative;
//   z-index: 1;
//   backdrop-filter: blur(40px);
//   border: solid 2px transparent;
//   background-clip: padding-box;
//   box-shadow: 10px 10px 10px rgba(46,54,68, 0.03);


//   h2 {
//       font-size: 1.7em;
//   }
  
//   h1, h2 {
//       margin: 0;
//   }
  
//   p {
//       margin: 0;
//   }


// }

// h1 {
//   font-size: 4em;
//   clip-path: inset(0 0 0 0);
// }




  