

.loader {
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.myspinner {
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}